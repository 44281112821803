<template>
  <div class="immediate">
    <!-- 立即分期 -->
    <div class="firstBox">
      <!-- 合作医院 -->
      <div class="hospital">
        <p class="top">
          <img src="./img/yiyuan.png" alt=""/>
          <span>合作医院</span>
          <template v-if="canEdit">
            <van-icon @click="toScan" size="30" name="scan"/>
          </template>
        </p>
        <p class="bottom">
          <span v-if="partnerName">{{ partnerName }}</span>
          <span v-else>{{ partnerName1 }}</span>
        </p>
      </div>

      <!-- 分期金额 -->
      <div class="divisionMoney">
        <van-field
          class="money"
          v-model="divMoney"
          @blur="contrastMoney"
          label="分期金额(元)"
          placeholder="请输入100的整数倍"
          input-align="right"
          clearable
          type="number"
        />
        <!-- <img @click="delInput" src="./img/del.png" alt="" /> -->
      </div>
      <!-- 选择期数 -->
      <div class="monthDigit">
        <van-field
          v-model="numberStage"
          is-link
          input-align="right"
          readonly
          name="picker"
          label="分期期数"
          placeholder=""
          @click="showNumber = true"
        />
        <van-popup v-model:show="showNumber" position="bottom">
          <van-picker
            value-key="value"
            :columns="numberData"
            @confirm="numberCheck"
            @cancel="showNumber = false"
          />
        </van-popup>
      </div>
    </div>
    <div class="secondBox">
      <!-- 申请项目 -->
      <div class="itemapply">
        <van-field
          is-link
          readonly
          name="picker"
          label="申请项目"
          input-align="right"
          placeholder=""
          v-model="itemNum"
          @click="showItem = true"
        />
        <van-popup v-model:show="showItem" position="bottom">
          <van-cascader
            v-model="itemValue"
            title="请选择美容项目"
            :options="options"
            @close="showItem = false"
            @finish="onFinish"
            :field-names="fieldNames"
          />
        </van-popup>
      </div>
      <div class="itemContent">
        <!-- 项目内容 -->
        <div
          class="itemContent_item"
          v-for="(item, index) in itemlist"
          :key="item"
        >
          <p class="itemName items">
            <span>{{ index + 1 }}.{{ item }}</span>
            <img @click="delItem(index)" src="./img/del.png" alt=""/>
          </p>
        </div>
      </div>
      <div class="itemMoneys">
        <p class="itemMoney items">
          <van-field
            input-align="right"
            label="项目总金额（元）"
            v-model="itemMoney"
            clearable
            type="number"
            @blur="contrastMoney1"
            placeholder="请输入项目总金额"
          />
        </p>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottomBox">
      <div class="bottomLeft">
        <p class="bottom_top">
          <span class="every_title">每期应还：</span>
          <!-- <span class="money_red" v-if="moneyList">{{moneyList.planAmt}}</span> -->
          <span class="money_red" v-if="shouldMoney"
          >￥{{ $filters.formatMoney(shouldMoney, 2) }}</span
          >
          <span class="money_red" v-else
          >￥{{ $filters.formatMoney(0, 2) }}</span
          >
        </p>
        <p class="bottom_bottom" @click="showBacklist">
          <span>查看还款计划</span>
          <van-icon class="toparrow" name="arrow-up"/>
        </p>
      </div>
      <div class="bottomRight" @click="submit">提交</div>
    </div>
    <!-- 点击提交显示弹出层 -->
    <van-popup v-model:show="showPop">
      <div class="popup">
        <p class="poptext">
          医院、申请项目、项目金额、借款金额、借款期限一经提交将无法修改，请确认后再提交！
        </p>
        <div class="twoBtn">
          <p class="mysee" @click="mysee">我再看看</p>
          <p class="mysure" @click="mysure">我要提交</p>
        </div>
      </div>
    </van-popup>
    <!-- 点击查看还款计划 -->
    <div class="backplan">
      <van-popup v-model:show="showBackamt" position="bottom">
        <div class="backAmt">
          <p class="backTitle">
            <span>选择计划仅供参考，以实际放款时间及计划为准</span>
            <img @click="closeBacklist" src="./img/close.png" alt=""/>
          </p>
          <div class="backList_item" v-for="item in moneyList" :key="item">
            <p>
              <span>第{{ item.planIndex }}期</span>
              <span class="gray">({{ item.planDate }})</span>
            </p>
            <p>
              <span>￥{{ item.planAmt }}</span>
            </p>
          </div>
          <!-- <div class="backList_item">
            <p><span>第一期</span> <span class="gray">(2020-07-16)</span></p>
            <p><span>￥1500</span></p>
          </div> -->
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  toRefs,
  reactive,
  onMounted,
  getCurrentInstance,
  onUnmounted,
  onBeforeUnmount
} from 'vue'
import APP from '@/utils/App'
import API from '../../server/api'
import { Toast } from 'vant'
import { GetUrlParams } from '../../utils/index'

export default {
  name: 'ImmediateStaging',
  setup () {
    onBeforeUnmount(() => {
      document.removeEventListener('ScanEvent', callBacks, true)
    })
    onUnmounted(() => {
      console.log(1111)
      document.removeEventListener('ScanEvent', callBacks, true)
    })
    const internalInstance = getCurrentInstance()
    // eslint-disable-next-line no-unused-expressions
    internalInstance.appContext.config.globalProperties
    const state = reactive({
      canEdit: true,
      divMoney: '',
      numberStage: '', // 分期期数
      showNumber: false, // 展示期数的下拉框
      numberData: [], // 期数列表
      showItem: false, // 展示项目列表
      itemData: ['jgg', 'fff'], // 项目列表
      showPop: false, // 提示框
      showBackamt: false, // 还款计划
      itemValue: '',
      itemlist: [],
      itemIdList: [],
      moneyList: [], // 还款计划
      itemId: '',
      itemMoney: '', // 项目总金额
      partnerName1: '',
      partnerName: '',
      creditNo: '', // 授信单号
      userId: '',
      bdNo1: '',
      bdNo: '',
      partnerNo1: '',
      partnerNo: '',
      bdInfo: {},
      itemNum: '',
      shouldMoney: '',
      fieldNames: {
        text: 'itemValue',
        value: 'id',
        children: 'children'
      },
      options: [],
      key: null,
      isWithdrawal: null
    })
    // 点击扫描商户二维码
    const toScan = () => {
      APP.JUMP_SCAN()
    }

    // 扫码请求回调
    async function callBacks (resultData) {
      const result = JSON.parse(resultData.data)
      if (result) {
        const data = {}
        data.bdNo = result.bd_no
        state.bdNo = result.bd_no
        data.partnerNo = result.partnerNo
        state.partnerNo = result.partnerNo
        data.partnerName = result.partnerName
        state.partnerName = result.partnerName
        data.userId = state.userId
        data.creditNo = state.creditNo
        console.log(data)
        state.bdInfo = data
        await scans(data)
        try {
          document.removeEventListener('ScanEvent', callBacks, true)
          document.addEventListener('ScanEvent', callBacks, true)
        } catch (error) {
          console.log(error, 'error')
        }
        Toast.success('识别成功')
      } else {
        Toast.fail('扫描失败,请重新扫描')
        setTimeout(() => {
          APP.BACK(-1)
        }, 1000)
      }
    }

    // 重新扫描商户二维码
    const scans = async (data) => {
      console.log('firstLog', data)
      const scanResult = await API.scanBdTwo(data)
      console.log(scanResult, '80808080')
    }
    // 借款试算
    const loanNum = async (data) => {
      const loanNum = await API.getLoanNum(data);
      if(loanNum.code==200){
         state.moneyList=loanNum.result;
      }
      console.log(state.moneyList, '借款试算')
      // state.moneyList = loanNum
      state.shouldMoney = state.moneyList[0].planAmt
    }

    // 点击确定显示期数
    const numberCheck = (value) => {
      state.numberStage = value
      state.showNumber = false
      if (state.divMoney && state.numberStage) {
        const money = {}
        money.loanIntentionAmount = state.divMoney
        money.loanIntentionNum = state.numberStage
        // money.creditNo=state.creditNo
        console.log(money, '钱')
        loanNum(money)
      }
    }
    // 失去焦点做判断
    const contrastMoney = () => {
      if (state.divMoney % 100 !== 0) {
        Toast.fail('请输入100的整数倍!')
        state.divMoney = ''
        return false
      }
      if (state.divMoney <= 0) {
        Toast.fail('请输入有效金额!')
        state.divMoney = ''
        return false
      }
      // state.divMoney = state.divMoney.toFixed(2)
      // state.divMoney =
      //   internalInstance.appContext.config.globalProperties.$filters.formatMoney(
      //     state.divMoney,
      //     2
      //   )
      state.numberStage = ''
    }
    const contrastMoney1 = () => {
      if (state.itemMoney <= 0) {
        Toast.fail('请输入有效金额！')
        state.itemMoney = ''
        return
      }
      // state.itemMoney1 = state.itemMoney
      // state.itemMoney =
      //   internalInstance.appContext.config.globalProperties.$filters.formatMoney(
      //     state.itemMoney,
      //     2
      //   )
    }
    // 点击确定显示项目
    const itemCheck = (value) => {
      //   state.infoVal = e.key;
      state.showItem = false
    }
    // 点击删除项目
    const delItem = (n) => {
      state.itemlist.splice(n, 1)
      state.itemIdList.splice(n, 1)
      state.itemNum = `已选择${state.itemlist.length}项`
    }
    // 点击清空输入框
    const delInput = () => {
      state.divMoney = ''
    }
    // 点击提交
    const submit = () => {
      if (!state.divMoney) {
        Toast.fail('请输入分期金额')
        return
      }
      if (!state.numberStage) {
        Toast.fail('请选择分期期数')
        return
      }
      if (state.itemlist.length === 0) {
        Toast.fail('请选择申请项目')
        return
      }
      if (!state.itemMoney) {
        Toast.fail('请输入项目总金额')
        return
      }
      state.showPop = true
    }
    // 点击我再看看
    const mysee = () => {
      // 提示款关闭
      state.showPop = false
    }
    // 保存借款意向
    const saveLoan = async (data) => {
      const saveData = await API.saveLoan(data)
      console.log(saveData)
      Toast.success('提交成功')
      setTimeout(function () {
        APP.BACK(-1)
      }, 1000)
    }
    // 我要提交
    const mysure = async () => {
      const loanList = {}
      loanList.bdNo = state.bdNo ? state.bdNo : state.bdNo1
      loanList.partnerNo = state.partnerNo ? state.partnerNo : state.partnerNo1
      loanList.partnerName = state.partnerName ? state.partnerName : state.partnerName1
      loanList.loanIntentionAmount = state.divMoney
      loanList.loanIntentionNum = state.numberStage
      loanList.projectId = state.itemIdList
      loanList.projectName = state.itemlist
      loanList.userId = state.userId
      loanList.projectAmount = state.itemMoney
      loanList.creditNo = state.creditNo
      loanList.creditType = state.key === '1' ? 'ADD_CREDIT' : 'NORMAL_CREDIT'
      // console.log(loanList,'提交的数据');
      await saveLoan(loanList)
    }
    // 点击查看还款计划
    const showBacklist = () => {
      if (!state.divMoney) {
        Toast.fail('请输入借款金额')
        return false
      }
      if (!state.numberStage) {
        Toast.fail('选择借款期数')
        return false
      }
      state.showBackamt = true
    }
    // 点击❌关闭还款计划
    const closeBacklist = () => {
      state.showBackamt = false
    }
    // 选择美容项目
    const onFinish = ({ selectedOptions }) => {
      state.showItem = false
      state.itemValue = selectedOptions
        .map((option) => option.itemValue)
        .join('-')
      state.itemId = selectedOptions.map((option) => option.id).join('-')
      state.itemIdList.push(state.itemId.substr(-3, 3))
      console.log(state.itemIdList, 'idid')
      state.itemlist.push(state.itemValue)
      state.itemNum = `已选择${state.itemlist.length}项`
    }
    // 获取借款意向单基础信息
    const getLoanList = async (data) => {
      const result = await API.getLoanData({ creditNo: data })
      state.partnerNo1 = result.partnerNo
      state.bdNo1 = result.bdNo
      state.partnerName1 = result.partnerName
      state.numberData = result.periods
      state.options = result.personList
    }

    // 查询意向单信息(反显)
    const getLastLoanData = async () => {
      const resultData = await API.intention()
      if (resultData) {
        console.log(resultData, '意向单')
        // state.divMoney =
        //   internalInstance.appContext.config.globalProperties.$filters.formatMoney(
        //     resultData.loanIntentionAmount,
        //     2
        //   )
        state.divMoney = resultData.loanIntentionAmount
        // state.itemMoney =
        //   internalInstance.appContext.config.globalProperties.$filters.formatMoney(
        //     resultData.projectAmount,
        //     2
        //   )
        state.itemMoney = resultData.projectAmount
        state.itemIdList = resultData.projectId
        const moneys = {}
        moneys.loanIntentionAmount = state.divMoney
        moneys.loanIntentionNum = resultData.loanIntentionNum
        await loanNum(moneys)
        state.numberStage = resultData.loanIntentionNum
        state.itemlist = resultData.projectName
        const len = resultData.projectName.length
        state.itemNum = `已选择${len}项`
        state.bdNo1 = resultData.bdNo
        state.partnerNo1 = resultData.partnerNo
        state.partnerName1 = resultData.partnerName
      }
    }

    // 页面加载，创建
    onMounted(async () => {
      // 修改页面标题
      APP.SET_TITLE('借款意向')
      // 注册事件
      document.addEventListener('ScanEvent', callBacks, true)
      // 从缓存当中拿值
      state.creditNo = localStorage.getItem('creditNo')
      state.userId = localStorage.getItem('userId')

      // 获取当前用户的一个状态
      const phone = localStorage.getItem('cellPhone')
      const result = await API.isUser({ phone })
      state.isWithdrawal = result.isWithdrawal

      // 初始化数据
      await getLoanList(state.creditNo)
      const query = GetUrlParams(window.location.href)
      const lastKey = query.key
      if (query.edit) {
        state.canEdit = false
      }

      state.key = lastKey
      if (lastKey === '1') {
        // 如果是提额
        await getLastLoanData()
      }
      // state.itemNum = '已选择0项'
    })
    // 页面卸载
    onUnmounted(() => {
      try {
        document.removeEventListener('ScanEvent', callBacks, true)
      } catch (error) {
        console.log(error, 'error')
      }
    })

    return {
      ...toRefs(state),
      numberCheck,
      itemCheck,
      delInput,
      delItem,
      submit,
      mysee,
      mysure,
      showBacklist,
      closeBacklist,
      onFinish,
      toScan,
      contrastMoney,
      contrastMoney1
    }
  }
}
</script>
<style lang="less" src="./index.less" scoped></style>
<style>
.immediate .divisionMoney .van-field__control {
  font-size: 36px;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #fe4040;
}

.immediate .itemMoneys .van-field__control {
  font-size: 36px;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #fe4040;
}

.immediate .divisionMoney .van-cell::after {
  border: none;
}

.immediate .divisionMoney .van-field__label {
  width: 30%;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}

.immediate .divisionMoney .van-cell__value {
  color: #bbbbbb;
}

.immediate .divisionMoney .van-field__control .van-field__control--right {
  font-size: 28px;
}

.immediate .divisionMoney .van-field {
  width: 100%;
  height: 100%;
  border: none;
}

.immediate .van-cell_label {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}

.immediate .van-cell__title.van-field__label {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}

.immediate .monthDigit .van-cell.van-cell--clickable.van-field {
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
}

.immediate .itemapply .van-cell.van-cell--clickable.van-field {
  /* height: 100%; */
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
}

.immediate .backplan .van-popup {
  background: none;
}

.immediate .backAmt {
  overflow-y: scroll;
}

.immediate .itemMoney .itemMoneys .van-cell__title.van-field__label {
  width: 40%;
}

.immediate .itemMoney .van-field__label {
  width: 40%;
}

.immediate .itemMoney .van-cell {
  padding: 0;
}

.immediate .itemapply .van-cell::after {
  border: none;
}

.immediate .backAmt .van-popup {
  position: initial;
  overflow-y: scroll;
  background: #fff;
}
</style>
